

body {
	padding: 0;
	margin: 0;
	background: #595959;
	overflow: hidden;
	font-family: 'Roboto', sans-serif;
}
a, a:visited {
	color: #929599;
	text-decoration: none;
}
a:hover {
	color: #fff;
	text-decoration: none;
}
.container { 
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
.center {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
.logo {
	max-width: 300px;
	height: auto;
	position: absolute;
	cursor: pointer;
}
.intro {
	margin: 40px 0 0 0;
	color: #0900ff;
}
.logo2 {
	max-width: 100px;
	height: auto;
	position: absolute;
	cursor: pointer;
}
@media screen and (min-width: 800px) {
	.logo {
	max-width: 500px;
	height: auto;
	}
}	
.footer {
	background: #343a40;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 20px;
	font-size: 1.5em;
	color: #212529;
	font-weight: 400;
}	
.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}
.column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	padding: 5px;
	text-align: center;  
}
@media screen and (min-width: 800px) {
	.column {
		flex: 1;
		text-align: center;
		padding: 0;
	}
}	  
form {
	margin: 10px;
}
.hide {
	display: none !important;
}
body .shell {
	width: 700px;
	height: 500px;
	border-radius: 3px;
	z-index: 3;
}
.shell.minimized {
	width: 700px;
	height: 30px !important;
}
.shell.maximized {
	height: 100%;
	width: 100%;
}
.shell.minimized .minimize {
	display: none !important;
}
body .shell .terminal {
	height: calc(100% - 29px);
	padding: 10px;
	--size: 1.1;
	/*
	* padding bottom 0 on terminal and margin
	* on .cmd will be in version 2.0.1
	* that fixes FireFox issue
	*/
	padding-bottom: 0;
}
body .shell .terminal .cmd {
	margin-bottom: 10px;
}
.shell .typed-cursor, .shell .cursor {
	background: transparent;
}
.shell > .status-bar .title {
	cursor: move;
}
/*
* fix to shell.js to center title to free space
*/
@supports (--css: variables) {
	.shell .terminal {
		--color: #aaa;
		--background: #292929;
	}
}   
.shell.custom .content.terminal {
	--background: #292929;
	--color: #aaa;
}
/*
* overwrite shell.js style because shell.js   
* selectors are stronger then terminal ones
*/
.cmd span.cursor {
	animation: none;
	width: auto;
	background-color: var(--background, #000);
}
.shell .terminal.content {
	font-size: 12px;
}
.cmd {
	background-color: inherit;
}
@supports (--css: variables) {
	.shell .terminal.content {
	font-size: calc(var(--size, 1) * 12px);
	}
}
/* fix for Firefox */
.terminal > .resizer, .terminal > .font .resizer {
	visibility: visible;
	pointer-events: none;
}
.terminal::-webkit-scrollbar-track {
	border: 1px solid var(--color, #aaa);
	background-color: var(--background);
}
.terminal::-webkit-scrollbar {
	width: 10px;
	background-color: var(--background);
}
.terminal::-webkit-scrollbar-thumb {
	background-color: var(--color, #aaa);
}